import React from 'react';
import { Router, navigate, useLocation } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, Toolbar, Typography } from '@material-ui/core';
import { Link } from 'gatsby-theme-material-ui';
import { useTranslation } from 'react-i18next';
import {
  Dashboard as DashboardRoute,
  Profile,
  Login,
  SendBulk,
  SendToEmployees,
  SendToCustomers,
  ManageEmployees,
  ManageCustomers,
  History,
} from '../components/routes';
import PrivateRoute from '../components/PrivateRoute';
import { SidebarHeader, Content } from '../components/layouts';
import { auth, enterprise } from '../state';
import { PreLoader } from '../components/custom';

const Dashboard = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) =>
    auth.selectors.selectIsLoggedIn(state)
  );
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (!isLoggedIn) {
      const response = dispatch(auth.actions.refreshToken());
      response.then(() => {
        dispatch(enterprise.actions.getInfo());
        setIsLoading(false);
      });
    } else {
      dispatch(enterprise.actions.getInfo());
    }
  }, []);

  if (isLoading) {
    return <PreLoader />;
  }

  if (isLoggedIn && location.pathname === '/dashboard/login') {
    navigate('/dashboard');
    return null;
  }
  const host = typeof window !== 'undefined' ? window?.location?.origin : '';

  return (
    <>
      {isLoggedIn ? <SidebarHeader /> : null}
      <Content withSidebar={isLoggedIn} md={12}>
        <Router
          basepath="/dashboard"
          style={{ minHeight: 'calc(100vh - 140px)' }}
        >
          <PrivateRoute path="/" component={DashboardRoute} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute
            path="/send-gift-cards-employees"
            component={SendToEmployees}
          />
          <PrivateRoute
            path="/send-gift-cards-customers"
            component={SendToCustomers}
          />
          <PrivateRoute path="/send-gift-cards-bulk" component={SendBulk} />
          <PrivateRoute path="/manage-employees" component={ManageEmployees} />
          <PrivateRoute path="/manage-customers" component={ManageCustomers} />
          <PrivateRoute path="/transaction-history" component={History} />
          <Login path="/login" />
        </Router>

        <Toolbar
          disableGutters
          // style={{ position: 'fixed', bottom: 10, left: 0, right: 0 }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" py={1}>
                {host === 'https://client.traversiersbourbonnais.com' ? (
                  <Typography variant="caption">
                    {t('poweredByAt')}{' '}
                    <Link
                      style={{ color: 'blue' }}
                      underline="always"
                      href="https://www.cfocus.ca"
                      target="_blank"
                    >
                      cFocus
                    </Link>{' '}
                    {t('and')}{' '}
                    <Link
                      style={{ color: 'blue' }}
                      underline="always"
                      href="https://www.pintuna.com/"
                    >
                      Pintuna
                    </Link>
                  </Typography>
                ) : (
                  <Typography variant="caption">
                    {t('poweredBy')}{' '}
                    <Link
                      style={{ color: 'blue' }}
                      underline="always"
                      href="https://www.pintuna.com/"
                    >
                      Pintuna
                    </Link>
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Content>
    </>
  );
};

export default Dashboard;
